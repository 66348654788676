.hide-on-small-screen {
  @media screen and (max-width: 800px) {
    display: none;
  }
}

.single-calendar {
  font-weight: bold;
  z-index: 99;
  border-radius: 4px;
  box-shadow: rgba(14, 63, 126, 0.06) 0px 0px 0px 1px, rgba(42, 51, 70, 0.03) 0px 1px 1px -0.5px,
    rgba(42, 51, 70, 0.04) 0px 2px 2px -1px, rgba(42, 51, 70, 0.04) 0px 3px 3px -1.5px,
    rgba(42, 51, 70, 0.03) 0px 5px 5px -2.5px, rgba(42, 51, 70, 0.03) 0px 10px 10px -5px,
    rgba(42, 51, 70, 0.03) 0px 24px 24px -8px;

  &-top {
    background: #d86156;
    padding: 1px 5px;
    color: #fff;
    font-size: 12px;
    text-transform: uppercase;
    text-align: center;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }

  &-body {
    text-align: center;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    padding: 1px 13px;

    &-number {
      display: block;
      font-size: 18px;
      padding: 0;
      line-height: 26px;
      margin-bottom: 0;
    }

    &-day {
      font-size: 12px;
      color: #b3b5b7;
      text-transform: capitalize;
    }
  }
}

@media screen and (max-width: 800px) {
  .single-calendar {
    &-body {
      padding: 5px;
    }
  }
}

// Small size option
.single-calendar-sm {
  .single-calendar-top {
    font-size: 9px;
    padding: 1px 11px;
  }

    .single-calendar-body-number {
      font-size: 17px;
      line-height: 24px;
    }
}
