.disable {
  pointer-events: none;
  color: #393f49e8;
}

.clickable {
  pointer-events: auto;
  color: #000000;
}

.pointer {
  cursor: pointer;
}

.ticket {
  position: relative;
  width: 100%;
  border: 1.7px solid rgb(215, 215, 215);
  display: grid;
  grid-template-columns: 28px 1fr;
  border-radius: 9px;

  &__left {
    position: relative;
    min-height: 100px;
    border-right: 1px dashed rgb(215, 215, 215);
  }

  &__content {
    padding: 22px 14px;
    height: 100%;

    .ticket--title {
      display: flex;
      justify-content: space-between;
    }

    .ticket--badge {
      font-size: 10px;
      font-weight: 500;
      border-radius: 4px;
      padding: 0.4rem;
      text-transform: uppercase;
      display: inline-block;
      text-align: center;

      &--danger {
        background: rgba(210, 48, 68, 0.16);
        color: #ed5a6c;
        border: 1px solid #ed5a6c;
      }

      &--success {
        background: rgba(48, 210, 48, 0.16);
        color: #37c24a;
        border: 1px solid #5aed6e;
      }
    }

    .ticket--line {
      width: 100%;
      height: 1px;
      background-color: rgb(215, 215, 215);
      margin-bottom: 1.4rem;
      margin-top: -0.85rem;
    }

    p {
      margin-bottom: 0.5rem;
    }

    &__bottom {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 18px;

      .ticket__counter {
        display: flex;
        border: 1px solid rgb(215, 215, 215);
        border-radius: 3px;

        button {
          background-color: #f4f4f4;
          text-align: center;
          // padding: 0.55rem 0.9rem;
          cursor: pointer;
          border: none;
        }

        button:nth-child(2) {
          background-color: #fff;
          border-left: 1px solid rgb(215, 215, 215);
          border-right: 1px solid rgb(215, 215, 215);
          cursor: text;
          border: none;
        }

        .ticket--number {
          padding: 6.5px !important;
          width: 44px;
          text-align: center;
          display: flex;
          justify-content: center;
          align-items: center;
          background: #fff;
          border: none;
          border-left: 1px solid rgb(215, 215, 215);
          border-right: 1px solid rgb(215, 215, 215);
        }

        input[type='number']::-webkit-inner-spin-button,
        input[type='number']::-webkit-outer-spin-button {
          -webkit-appearance: none;
        }
      }
    }
  }
}

.ticket--blue {
  color: #4a8bdb;
}

.ticket--price {
  color: #393f49e8;
}

.ticket__circle {
  height: 13px;
  width: 13px * 2;
  background-color: #fff;
  display: inline-block;
  position: absolute;
  right: -13.5px;
  border: 1.7px solid rgb(215, 215, 215);
}

.ticket__circle:nth-child(1) {
  top: -1.7px;
}

.ticket__circle:nth-child(2) {
  bottom: -1.7px;
}

.circle--top {
  border-top-left-radius: 13px * 2;
  border-top-right-radius: 13px * 2;
  border-bottom: none !important;
}

.circle--bottom {
  border-bottom-left-radius: 13px * 2;
  border-bottom-right-radius: 13px * 2;
  border-top: none !important;
}

.ticket.is-invalid {
  border: 1.7px solid rgba(220, 53, 69, 0.25);
  box-shadow: '0 0 0 0.2rem rgba(220, 53, 69, 0.16)';

  .ticket__left {
    border-end-start-radius: 8px;
    border-start-start-radius: 8px;
    background-color: #fef9fa;

    .ticket__circle {
      border: 1.7px solid rgba(220, 53, 69, 0.25);
    }
  }

  .ticket__content {
    border-end-end-radius: 8px;
    border-start-end-radius: 8px;
    background-color: #fef9fa;
  }
}
