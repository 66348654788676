.rating-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;
}

// .sum-rating {
//   font-size: 1.55rem;
// }

// .sum-rating-sub {
//   font-size: 1.3rem;
// }

// .card-review {
//   padding: 1rem 1.5rem;
// }

.text-percent_reviews {
  width: 100px;

  @media (min-width: 400px) {
    width: 90px;
  }

  @media (min-width: 500px) {
    width: 70px;
  }

  @media (min-width: 1000px) {
    width: 70px;
  }
}
