@import "../../../../assets/css/variables";

$second-title-color: #282828dd;
$third-title-color: #3b3b3bdd;
$forth-title-color: #595959dd;
$padding-card: 13px 19.5px;
$sm-padding-card: 16px 13px;
$secondary-background-product: #f8f9fa;
$background-icon-primary-color: #d1e8ff;
$border-icon-outline-color: #0b74f4;

.product-page {
  overflow-x: hidden;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: #2e2e2edd;
  }

  p {
    font-size: 13.65px;

    @media screen and (max-width: 800px) {
      font-size: 14.3px;
    }
  }

  h3 {
    font-weight: 700;
    margin-bottom: 0;

    @media screen and (max-width: 800px) {
      font-size: 16.9px;
    }
  }
}

// Start variant option component
.variant-option-radio {
  label {
    width: 100%;
    // background-color: #f8f9fa;
    display: flex;
    gap: 0.5rem;
    border-radius: 0.2rem;
    padding: 0.8rem 0.75rem;
    cursor: pointer;
    // margin-bottom: 0.4rem;

    .variant-option-label {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;

      h5:first-child {
        color: #3b3b3bdd;
      }
    }
  }
}

.selected-variant {
  margin-bottom: 1.2rem;

  &-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 0.7rem 0.7rem;

    h4 {
      margin-bottom: 0;
      font-size: 1.2rem;
    }

    .selected-variant-name {
      font-weight: 700;
    }
  }

  &-desc {
    padding: 8px 9.1px;
  }
}
// End variant option component

// Physical product checkout option
.physical-detail {
  padding-top: 20px;

  &-flex {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 21px;
    gap: 13px;

    input {
      width: 90px;
    }

    .physical-product-variant-select {
      width: 70%;
    }
  }

  // &-block {
  //   .physical-detail-title {
  //     margin-bottom: 0.3rem;
  //   }
  // }

  &-info {
    margin-bottom: 21px;
  }

  h4 {
    margin-bottom: 0;
    font-weight: 500;
    font-size: 14.95px;
    color: $third-title-color;
  }

  .physical-detail-title {
    font-weight: 600;
  }
}
// End physical product checkout option

// Course material contents
.course-product-content {
  .course-module-product {
    background: $secondary-background-product;
    border: none;
    margin: 4px;
    padding: 16px;
    border-radius: 4px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;

    h4 {
      margin-bottom: 0;
      font-weight: 600;
      font-size: 15px;
      color: #454545e8;
    }
  }

  .sub-module-title {
    font-size: 14px;
    color: $second-title-color !important;
    letter-spacing: -0.2px;
  }

  .preview-module {
    display: flex;
    gap: 13px;
    justify-content: space-between;
    padding: 7px 20px;

    &-title {
      display: flex;
      align-items: start;
      gap: 14px;

      .preview-module-icon {
        background: $background-icon-primary-color;
        min-width: 33px;
        min-height: 33px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;

        .module-icon {
          color: #2b84f1;
        }
      }
    }
  }
}

// Bootcamp session
.bootcamp-session {
  display: flex;
  background: $secondary-background-product;
  border: none;
  margin: 4px;
  padding: 16px;
  border-radius: 4px;
  gap: 12px;

  &-number h3 {
    background: $background-icon-primary-color;
    font-size: 13px;
    padding: 3px 8px;
    border-radius: 50%;
    color: #2b84f1;
    border: 1px solid $border-icon-outline-color;
  }

  h4 {
    margin-bottom: 0;
    font-weight: 700;
    font-size: 14.95px;
    color: #454545e8;
  }
}

// Start promo info
.syllabus-item,
.audience-item,
.outcome-item {
  display: flex;
  align-items: start;
  gap: 13px;

  &-icon {
    font-weight: 600;
  }

  .outcome-item-icon {
    background: #197bff;
    color: #fff;
    border-radius: 50%;
    padding: 3px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &-info {
    &-title {
      margin-bottom: 0;
      font-size: 15.21px;
      font-weight: 600;
      margin-bottom: 12px;
    }
  }
}

.faq {
  &-item {
    &-question {
      display: flex;
      justify-content: space-between;
      background-color: #f8f9fa;
      margin-bottom: 13px;
      border: none;
      margin: 4px;
      padding: 14px 16px;
      border-radius: 4px;
      cursor: pointer;
      border-radius: 2px;

      h3 {
        font-size: 15.6px;
      }
    }

    &-answer {
      padding: 14px 26px 14px;

      p {
        margin-bottom: 0;
      }
    }
  }
}
// End promo info

.read-more-cta {
  font-size: 12px;
  color: rgb(108, 117, 125);
  cursor: pointer;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 999;
}

.mobile-checkout {
  display: none;
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 999;
  width: 100%;
  background: #fff;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;

  @media screen and (max-width: 640px) {
    display: grid !important;
    grid-template-columns: 17% 1fr;
    gap: 7px;
    padding: 10px;
  }
}

.powered-by-mobile {
  @media screen and (max-width: 640px) {
    // padding-bottom: 85px !important;
  }
}

.product-cover {
  max-width: 400px;
  border-radius: 0.25rem 0.25rem 0 0;
  margin-top: 20px;
}

@media screen and (max-width: 500px) {
  .product-cover {
    max-width: 100%;
    border-radius: 0;
    margin-top: 0;
  }
}

.cover-video-product {
  height: 600px;
  border-radius: 10px;

  @media screen and (max-width: 1020px) {
    height: 450px;
  }

  @media screen and (max-width: 850px) {
    height: 300px;
  }

  @media screen and (max-width: 600px) {
    height: 260px;
  }
}

// Product header
.wrap-product-header {
  padding: 16.25px 35.1px 23.4px;

  @media screen and (max-width: 800px) {
    padding: $padding-card;
  }

  .main-product-title {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;

    @media screen and (max-width: 1000px) {
      display: block;
    }

    h1 {
      font-weight: 700;
      font-size: 26px;
      // margin-bottom: 0;
      margin-bottom: 7px;
      width: 65%;

      @media screen and (max-width: 1000px) {
        width: 100%;
        // margin-bottom: 1rem;
      }

      @media screen and (max-width: 800px) {
        font-size: 25px;
      }

      @media screen and (max-width: 600px) {
        font-size: 20.8px;
        margin-bottom: 7px;
      }
    }

    .sub-product-title {
      display: flex;
      align-items: center;
      gap: 2rem;
      width: 35%;

      @media screen and (max-width: 1000px) {
        width: 100%;
        margin-top: 10pt;
      }

      .sub-product-wrapper {
        display: flex;
        gap: 13px;
        align-items: center;
        width: fit-content;

        .sub-product-info {
          h4 {
            font-size: 16px;
            font-weight: 600;
            color: $third-title-color;
            margin-bottom: 4px;
          }

          h5 {
            font-size: 15px;
            font-weight: 500;
            color: $forth-title-color;
            margin-bottom: 0;
          }
        }
      }
    }
  }
}

// Start membership tier option
.membership-tier-card {
  cursor: pointer;
  padding: $sm-padding-card;
  // margin: 13px 0;

  &-active {
    border: 1px solid $border-icon-outline-color;
  }

  h4 {
    font-weight: 600;
    margin-bottom: 7px;
  }

  h5 {
    font-weight: 600;
    margin-bottom: 13px;
    font-size: 13px;
    color: $third-title-color;
    color: $forth-title-color;
  }

  p {
    margin-bottom: 7px;
  }

  .read-more-tier {
    margin-top: 13px;
    font-weight: 600;
    color: rgb(41, 128, 185);
  }
}

.tier-option-empty {
  padding: 26px 0 13px;
  text-align: center;

  p {
    margin-bottom: 0;
  }
}
// End membership tier option

.wrap-product-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1.5rem;
}

.product-bundle {
  display: flex;
  gap: 13px;

  &-img {
    width: 60px;
    border-radius: 3px;

    img {
      width: 100%;
      border-radius: 3px;
    }
  }

  &-info {
    display: flex;
    justify-content: space-between;
    width: 100%;

    h4,
    h3 {
      margin-bottom: 0;
    }

    h3 {
      font-size: 16.25px;
      margin-bottom: 7px;
    }

    h4 {
      font-size: 15px;
    }
  }

  &-price {
    h4 {
      margin-bottom: 0;
      margin-top: -0.1px;
      font-weight: 600;
      font-size: 14.3px;
    }
  }
}

.product-location {
  &-map {
    position: relative;
    height: 250px;

    iframe {
      width: 100%;
      border: none;
      height: 100%;
    }
  }

  &-info {
    h3 {
      margin-bottom: 10px;
    }

    p {
      margin-bottom: 0;
    }
  }
}

// Meta data detail component
.metadata-detail {
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;

  h4 {
    font-weight: 600;
    color: $third-title-color;
    font-size: 14.3px;
    margin-bottom: 5px;
  }

  p {
    margin-bottom: 0;
  }
}
// End meta data detail component

// Settings
$global-font-size: 14px;
$global-line-height: 1.8;
$global-bg-color: white;

@mixin read-more(
  $rows: 4,
  $font-size: $global-font-size,
  $line-height: $global-line-height,
  $bg-color: $global-bg-color,
  $trigger-color: #2980b9,
  $animation: 500ms ease,
  $el: (
    content: ".content",
    trigger: ".trigger",
    expander: ".expanded",
  ),
  $hover: false
) {
  // Optimal height based on your font size and line-height
  $height: (($font-size * $line-height) * $rows);
  @if map-get($el, trigger) != null {
    $height: $height + ($font-size * $line-height); // + 1 for trigger
  }

  @if $hover {
    @if map-get($el, content) {
      #{map-get($el, content)} {
        max-height: $height;
        &::before {
          height: $font-size + ($font-size * 2);
        }
      }
    }
  } @else {
    position: relative;
    text-decoration: none;
    line-height: $line-height;
    cursor: pointer;

    @if map-get($el, trigger) {
      cursor: text;
      #{map-get($el, trigger)} {
        //display: block;
        //position: absolute;
        bottom: 0;
        cursor: pointer;
        color: $trigger-color;
        font-weight: bold;
      }
    }

    @if map-get($el, content) {
      #{map-get($el, content)} {
        position: relative;
        overflow: hidden;
        max-height: $height;
        transition: max-height $animation;
        &::before {
          content: "";
          position: absolute;
          bottom: 0;
          width: 100%;
          height: $font-size + ($font-size * 1.5);
          background: linear-gradient(
            to bottom,
            rgba(255, 255, 255, 0) 0%,
            rgba($bg-color, 1) 50%,
            rgba($bg-color, 1) 100%
          ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
          @if map-get($el, expander) != null {
            transition:
              opactiy $animation,
              visibility $animation;
          }
        }
      }
    }

    @if map-get($el, expander) {
      &#{map-get($el, expander)} {
        #{map-get($el, content)}::before,
        #{map-get($el, trigger)} {
          opacity: 0;
          visibility: hidden;
        }
        #{map-get($el, content)} {
          max-height: 600px; // great value for height animation
        }
      }
    }
  }
}

.canvas-auto {
  canvas {
    width: unset !important;
    height: unset !important;
    max-width: 100% !important;
  }
}

.dark-font {
  color: $color_dark_8;
}
